import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import GLayout from './component/GLayout'
import { getLoggedUsername, getRoles, getUserProfile } from './config/keycloak'
import convert from './component/assets/convert.svg'
import TimerPopup from './component/TimerPopup'
import { t } from 'i18next'
import './i18n'
import './index.css'
import env from './config/env'

const Convert = lazy(() => import('./pages/Convert'))
const MultiTabError = lazy(() => import('./pages/MultiTabError'))
const PermissionError = lazy(() => import('./pages/PermissionError'))
const ResponsiveInfo = lazy(() => import('./pages/ResponsiveInfo'))

const App: React.FC = () => {
    const navigate = useNavigate()

    const itemList = [
        {
            key: 'convert',
            name: t('CONVERT'),
            nav: '/',
            icon: () => <img src={convert} alt='logo' />,
        },
    ]

    const checkHasViewPermission = () => {
        const rolePermissionList = getRoles()
        if (!rolePermissionList.includes('ROLE_R_ORDER_HISTORY') || !getUserProfile()) {
            navigate('/error')
        }
    }

    useEffect(() => {
        document.title = env.keycloakClientId === 'myfx-pro' ? '4XPro' : 'myFX'
        checkHasViewPermission()
    }, [])

    const itemTypeList = itemList.map(list => ({
        key: list.key,
        icon: React.createElement(list.icon),
        label: list.name,
    }))

    const onThemeChange = (theme: string) => {
        localStorage.setItem('theme', theme)
    }

    const handleResize = () => {
        if (window.innerWidth < 481) {
            navigate('/responsive')
        }
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const RouteLoading = () => {
        return (
            <div className='mt-5'>
                <span className='block w-48 h-12 rounded skeleton'></span>
                <div className='mt-5 border rounded'>
                    <div className='p-5 border-b'>
                        <span className='block w-48 h-8 rounded skeleton'></span>
                    </div>
                    <div className='p-5'>
                        <div className='w-full h-screen skeleton'></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {getRoles() === null ? (
                <RouteLoading />
            ) : (
                <Suspense fallback={<RouteLoading />}>
                    <Routes>
                        <Route
                            path={'/'}
                            element={
                                <GLayout
                                    style={{ fontSize: 18 }}
                                    items={itemTypeList}
                                    defaultSelectedKeys={['2']}
                                    onThemeChange={val => {
                                        onThemeChange(val)
                                    }}
                                    loginName={getLoggedUsername()}
                                    selectedKeys={['convert']}
                                    defaultTheme={env.keycloakClientId === 'myfx-pro' ? 'dark' : 'light'}
                                >
                                    <Convert />
                                    <TimerPopup />
                                </GLayout>
                            }
                        />
                        <Route path={'/denied'} element={<MultiTabError />} />
                        <Route path={'/error'} element={<PermissionError />} />
                        <Route path={'/responsive'} element={<ResponsiveInfo />} />
                        <Route path='*' element={<PermissionError />} />
                    </Routes>
                </Suspense>
            )}
        </>
    )
}

export default App
